class Slide
{
    #children;
    #elem;
    #dots;
    #prev;
    #next;
    #selectedIndex = 0;
    #activeClass = '';
    #startOffsets = [];
    #animationFraction = 1;
    #swipeDuration = 1;
    #timeFunction = new TimeFunction(TimeFunction.EaseInOut);
    #lastTime;
    #leftOffset = 0;
    #dotInterval = 1;

    /**
     *
     * @param elem
     * @param {?Object} options
     * @param {?Element} options.dots
     * @param {?Element} options.prev
     * @param {?Element} options.next
     * @param {?string} options.activeClass
     * @param {?float} options.swipeDuration
     * @param {?float} options.leftOffset
     * @param {?int} options.dotInterval
     * @param {?TimeFunction} options.timeFunction
     */
    constructor(elem, options = {})
    {
        const self = this;
        this.#children = elem.childNodes;
        this.#elem = elem;

        if ('activeClass' in options)
            this.#activeClass = options.activeClass;

        if ('swipeDuration' in options)
            this.#swipeDuration = options.swipeDuration;

        if ('timeFunction' in options)
            this.#timeFunction = options.timeFunction;

        if ('leftOffset' in options)
            this.#leftOffset = options.leftOffset;

        if ('dots' in options)
            this.#dots = options.dots;

        if ('dotInterval' in options)
            this.#dotInterval = options.dotInterval;

        if ('prev' in options)
        {
            this.#prev = options.prev;
            this.#prev.addEventListener('click', function ()
            {
                self.selectPrev();
            });
        }

        if ('next' in options)
        {
            this.#next = options.next;
            this.#next.addEventListener('click', function ()
            {
                self.selectNext();
            });
        }

        if ('dots' in options)
        {
            this.#dots = options.dots;
        }

        this.setDotInterval(this.#dotInterval);
        this.selectSlide(0);
        this.animate();
    }

    setDotInterval(interval)
    {
        const self = this;
        if (this.#dots != null)
        {
            this.#dotInterval = interval;
            this.#dots.innerHTML = '';

            const childCount = this.#children.length;
            for (let i = 0; i < childCount; i += this.#dotInterval)
            {
                const dot = document.createElement('div');
                dot.classList.add('dot');
                dot.dataset.index = i.toString();
                dot.classList.toggle('selected', this.#selectedIndex === i);
                dot.addEventListener('click', function () {
                    self.selectSlide(i);
                })
                this.#dots.appendChild(dot);
            }
        }
    }

    getElement() {
        return this.#elem;
    }

    selectSlide(index)
    {
        const childCount = this.#children.length;
        if (childCount === 0) return;

        index = (index % childCount + childCount) % childCount;
        this.#children[this.#selectedIndex].classList.remove(this.#activeClass);
        this.#children[index].classList.add(this.#activeClass);

        if (this.#selectedIndex !== index)
        {
            this.#selectedIndex = index;
            this.beginAnimation();
        }

        if (this.#dots != null)
        {
            const dots = this.#dots.getElementsByClassName('dot');
            for (let dot of dots)
            {
                dot.classList.toggle('selected', Number.parseInt(dot.dataset.index) === index);
            }
        }
    }

    selectPrev()
    {
        this.selectSlide(this.#selectedIndex - 1);
    }

    selectNext()
    {
        this.selectSlide(this.#selectedIndex + 1);
    }

    selectPrevPage()
    {
        let curIndex = this.#selectedIndex;
        let pageMap = [];
        for (let i = 0; i < this.#children.length; i += this.#dotInterval) {
            pageMap.push(i);
        }

        let closestIndex;
        for (let i = pageMap.length - 1; i >= 0; i -= 1) {
            closestIndex = pageMap[i];
            if (closestIndex < curIndex)
                break;
        }

        this.selectSlide(closestIndex);
    }

    selectNextPage()
    {
        let curIndex = this.#selectedIndex;
        let closestIndex;
        for (let i = 0; i < this.#children.length; i += this.#dotInterval) {
            closestIndex = i;
            if (closestIndex > curIndex)
                break;
        }

        this.selectSlide(closestIndex);
    }

    calcOffsets(index)
    {
        const childCount = this.#children.length;
        if (childCount === 0 || index >= childCount) return;

        const width = this.#elem.getBoundingClientRect().width;
        let totalOffset = 0;
        let negativeOffset = 0;

        for (let i = 0; i <= index; i++)
        {
            const child = this.#children[i];
            let value = child.getBoundingClientRect().width;
            if (i === index)
                value *= this.#leftOffset;
            if (i > 0)
                value += Number.parseFloat(getComputedStyle(this.#children[i - 1]).marginRight.replace('px', ''));

            negativeOffset += value;
        }

        const offsets = [];
        for (let i = 0; i < childCount; i++)
        {
            const child = this.#children[i];
            offsets.push((width * this.#leftOffset) + totalOffset - negativeOffset);
            totalOffset += child.getBoundingClientRect().width + Number.parseFloat(getComputedStyle(child).marginRight.replace('px', ''));
        }

        return offsets;
    }

    animate(time = null)
    {
        const self = this;
        const destination = this.#selectedIndex;
        if (time === null)
        {
            requestAnimationFrame(function (time) {
                self.animate(time);
            })
            return;
        }

        const startOffsets = this.#startOffsets;
        const targetOffsets = this.calcOffsets(destination);
        const currentOffsets = [];

        const childCount = this.#children.length;
        for (let i = 0; i < childCount; i++)
        {
            currentOffsets.push((startOffsets[i] ?? 0) + ((targetOffsets[i] - (startOffsets[i] ?? 0)) * this.#timeFunction.sample(this.#animationFraction)));
        }

        let maxHeight = 0;
        for (let i = 0; i < childCount; i++)
        {
            const child = this.#children[i];
            let height = child.getBoundingClientRect().height;
            if (maxHeight < height)
                maxHeight = height;

            child.style.left = (currentOffsets[i] ?? 0) + 'px';
        }
        this.#elem.style.height = maxHeight + 'px';

        this.#animationFraction += (time - (this.#lastTime ?? time)) / 1000 / this.#swipeDuration;
        this.#animationFraction = Math.max(0, Math.min(1, this.#animationFraction));
        this.#lastTime = time;

        requestAnimationFrame(function (time) {
            self.animate(time);
        })

        const computedStile = getComputedStyle(this.#elem);
        const propertyValue = computedStile.getPropertyValue('--dot-interval');
        if (propertyValue !== '' && Number.parseInt(propertyValue) !== this.#dotInterval)
        {
            this.setDotInterval(Number.parseInt(propertyValue));
        }
    }

    beginAnimation()
    {
        this.#animationFraction = 0;
        this.#startOffsets = [];

        const childCount = this.#children.length;
        for (let i = 0; i < childCount; i++)
        {
            const child = this.#children[i];
            this.#startOffsets.push(Number.parseFloat(child.style.left.replace('px', '')));
        }
    }
}

window.Slide = Slide;
